import { useEffect, useState } from "react";
import { Menu, X } from "react-feather";
import { Link } from "react-router-dom";
import logo from "../assets/logo.webp";
import { NavLink } from "./NavLink";

function Header({ setWalletAddress, data }) {
  const [isNavOpen, setIsNavOpen] = useState(false);

  console.log(data);
  useEffect(() => {
    if (window.innerWidth < 1000) {
      setIsNavOpen(false);
    } else {
      setIsNavOpen(true);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth < 1000) {
        setIsNavOpen(false);
      } else {
        setIsNavOpen(true);
      }
    });
  }, []);

  return (
    <div className="header">
      <div className="header__wrapper">
        <Link to="/">
          <img src={logo} alt="logo" className="header__logo" />
        </Link>

        <div className="header__btns">
          <button
            className="header__menu"
            onClick={() => {
              isNavOpen ? setIsNavOpen(false) : setIsNavOpen(true);
            }}
          >
            {isNavOpen ? (
              <X size={20} color="currentColor" />
            ) : (
              <Menu size={20} color="currentColor" />
            )}
          </button>
        </div>
        {isNavOpen ? (
          <div className="header__nav">
            <NavLink to="/" isPending={true}>Sales</NavLink>
            <NavLink to="/" isPending={true}>Vote</NavLink>
            <NavLink to="/" isPending={true}>Stake</NavLink>
            <NavLink to="/" isPending={true}>
              Bridge
            </NavLink>
            {/* <Link to="/ido" className="header__nav__link">
              Sales
            </Link>
            <Link to="/sales" className="header__nav__link">
              Sales
            </Link>
            <Link to="/governance" className="header__nav__link">
              Governance
            </Link>
            <Link to="/stake" className="header__nav__link">
              Stake
            </Link>
            <a
              href="https://docs.lunastarter.io"
              target="_blank"
              className="header__nav__link"
            >
              Whitepaper
            </a>
            <Link
              to="/"
              onClick={() => {
                setTimeout(() => {
                  document.getElementById("footer").scrollIntoView();
                }, 300);
              }}
              className="header__nav__link"
            >
              Social Links
            </Link> */}
          </div>
        ) : null}
        {data.Balance === null ? (
          <button
            onClick={setWalletAddress}
            className="home__section__buttons__btn home__section__buttons__btn__primary"
          >
            Connect Wallet
          </button>
        ) : (
          <button className="home__section__buttons__btn home__section__buttons__btn__primary">
            Connected Wallet
          </button>
        )}
      </div>
    </div>
  );
}

export default Header;
