export default function RoadMapSection({ roadMapPic }) {
  return (
    <div className="road__map__section">
      <div className="road__map__section__left">Our Timeline</div>
      <div className="road__map__section__right">
        <img
          src={roadMapPic}
          alt="roadMapPic"
          className="road__map__section__img"
        />
      </div>
    </div>
  );
}
