import { useState } from "react";
import { Lock, Unlock } from "react-feather";
import { ethers } from "ethers";
import { useEffect } from "react";
import local from "../local.json";
import { getValue } from "../utils/getValue";
import TableRow from "../components/TableRow";

export default function Stake() {
  const [lock, setLock] = useState(true);
  const [totalStaked, setTotalStaked] = useState(0);
  const [bst, setBst] = useState(0);
  const [myStakes, setMyStakes] = useState(0);
  const [currentAllowance, setCurrentAllowance] = useState(0);
  const [allowanceAmount, setAllowanceAmount] = useState("");
  const [stakingAmount, setStakingAmount] = useState("");
  const [stakingOption, setStakingOption] = useState(0);
  const [disable, setDisable] = useState(false);
  const [tableData, setTableData] = useState([]);

  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  function changeValueToPowerAndString(value) {
    return (value * 10 ** 18).toString();
  }

  async function unStake(value) {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const usdcContract = new ethers.Contract(
      local.stake.address,
      local.stake.abi,
      signer
    );

    const tx = await usdcContract.unstake(value.toString(), {
      gasPrice: 20e9,
    });
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);
    OnAllowanceQuery();
    OnStakeQuery();
  }

  async function onIncreaseAllowance() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const usdcContract = new ethers.Contract(
      local.token.address,
      local.token.abi,
      signer
    );

    const tx = await usdcContract.increaseAllowance(
      local.stake.address,
      changeValueToPowerAndString(allowanceAmount),
      {
        gasPrice: 20e9,
      }
    );
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);
    OnAllowanceQuery();
    OnStakeQuery();
  }

  async function onDescreaseAllowance() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const usdcContract = new ethers.Contract(
      local.token.address,
      local.token.abi,
      signer
    );

    const tx = await usdcContract.decreaseAllowance(
      local.stake.address,
      changeValueToPowerAndString(allowanceAmount),
      {
        gasPrice: 20e9,
      }
    );
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);
    OnAllowanceQuery();
    OnStakeQuery();
  }

  async function onStake() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const usdcContract = new ethers.Contract(
      local.stake.address,
      local.stake.abi,
      signer
    );

    const tx = await usdcContract.stake(
      changeValueToPowerAndString(stakingAmount),
      stakingOption,
      lock,
      {
        gasPrice: 20e9,
      }
    );
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);
    OnAllowanceQuery();
    OnStakeQuery();
  }

  async function OnAllowanceQuery() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let userAddress = await signer.getAddress();

    const usdcContract = new ethers.Contract(
      local.token.address,
      local.token.abi,
      signer
    );
    const spender = local.stake.address;
    let query = await usdcContract.allowance(userAddress, spender);
    query = ethers.utils.formatUnits(query, 18);
    setCurrentAllowance(query);
    OnStakeQuery();
  }

  async function OnStakeQuery() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let userAddress = await signer.getAddress();

    const usdcContract = new ethers.Contract(
      local.stake.address,
      local.stake.abi,
      signer
    );

    let query = await usdcContract.userData(userAddress);

    console.log("tabledata", query);
    setTableData(query.stakes);
    setMyStakes(getValue(query.my_staked._hex));
    setTotalStaked(getValue(query.state.total_staked._hex));
  }

  async function OnBSTQuery() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let userAddress = await signer.getAddress();

    const usdcContract = new ethers.Contract(
      local.token.address,
      local.token.abi,
      signer
    );

    let query = await usdcContract.balanceOf(userAddress);
    // query = ethers.utils.formatUnits(query, 18);
    console.log(query._hex);
    setBst(getValue(query._hex));
  }

  useEffect(() => {
    OnAllowanceQuery();
    OnStakeQuery();
    OnBSTQuery();
  }, []);

  return (
    <>
      <div className="stake__home__section">
        <div className="stake__home__section__heading">staking dashboard</div>
        <div className="stake__home__section__cards">
          <div className="stake__home__section__card stake__home__section__card__new">
            <div className="stake__home__section__card__heading">{bst}</div>
            <div className="stake__home__section__card__sub__heading">
              My BST
            </div>
          </div>
          <div className="stake__home__section__card stake__home__section__card__primary">
            <div className="stake__home__section__card__heading">
              {totalStaked}
            </div>
            <div className="stake__home__section__card__sub__heading">
              Total Stakes
            </div>
          </div>
          <div className="stake__home__section__card stake__home__section__card__secondary">
            <div className="stake__home__section__card__heading">
              {myStakes}
            </div>
            <div className="stake__home__section__card__sub__heading">
              My Stakes
            </div>
          </div>
        </div>
      </div>
      <div className="stake__types">
        <div className="stake__types__entry">
          <input
            type="radio"
            className="stake__types__entry__input"
            name="stake__types__entry__input"
            defaultChecked
            onClick={() => setLock(true)}
          />
          <div className="stake__types__entry__content">
            <Lock size={15} color="currentColor" />
            <span>Locked</span>
          </div>
        </div>
        <div className="stake__types__entry">
          <input
            type="radio"
            className="stake__types__entry__input"
            name="stake__types__entry__input"
            onClick={() => setLock(false)}
          />
          <div className="stake__types__entry__content">
            <Unlock size={15} color="currentColor" />
            <span>Unlocked</span>
          </div>
        </div>
      </div>
      <div className="stake__table">
        <div className="stake__table__header">
          <div className="stake__table__header__entry">Staking Date</div>
          <div className="stake__table__header__entry">Amount</div>
          <div className="stake__table__header__entry">
            {lock ? (
              <>
                Unstaking Allowed After <span>(Remaining Days)</span>
              </>
            ) : (
              <>
                Shouldn't Unstake Before <span>(Remaining Days)</span>
              </>
            )}
          </div>
          <div className="stake__table__header__entry">Reward earned</div>
          <div className="stake__table__header__entry">APR</div>
          <div className="stake__table__header__entry"></div>
        </div>
        <div className="stake__table__content">
          {tableData
            .filter((item) => item.locked === lock && item.active === true)
            .map((item, i) => (
              <TableRow
                key={i}
                data={item}
                index={i}
                lock={lock}
                unStake={unStake}
              />
            ))}
        </div>
      </div>
      <div className="allowance__form">
        <div className="allowance__form__heading">Allowance</div>
        <div className="allowance__form__current">Current Allowance</div>
        <div className="allowance__form__current__value">
          {currentAllowance}
        </div>
        <input
          type="number"
          placeholder="Enter Value"
          className="allowance__form__input"
          value={allowanceAmount}
          onChange={(e) => {
            setAllowanceAmount(e.target.value);
          }}
        />
        <div className="allowance__form__buttons">
          <button
            className="allowance__form__buttons__primary"
            disabled={disable}
            onClick={onDescreaseAllowance}
          >
            Decrease Allowance
          </button>
          <button
            className="allowance__form__buttons__secondary"
            onClick={onIncreaseAllowance}
            disabled={disable}
          >
            Increase Allowance
          </button>
        </div>
      </div>
      <div className="allowance__form">
        <div className="allowance__form__heading">STAKING</div>
        <input
          type="number"
          placeholder="Enter Value"
          className="allowance__form__input"
          onChange={(e) => {
            setStakingAmount(e.target.value);
          }}
        />
        <div className="allowance__form__duration">
          <div className="allowance__form__duration__entry">
            <input
              type="radio"
              name="allowance__form__duration__entry__input"
              className="allowance__form__duration__entry__input"
              defaultChecked
              onChange={() => {
                setStakingOption(0);
              }}
            />
            <div className="allowance__form__duration__entry__content">
              30 Days
            </div>
          </div>
          <div className="allowance__form__duration__entry">
            <input
              type="radio"
              name="allowance__form__duration__entry__input"
              className="allowance__form__duration__entry__input"
              onChange={() => {
                setStakingOption(1);
              }}
            />
            <div className="allowance__form__duration__entry__content">
              45 Days
            </div>
          </div>
          <div className="allowance__form__duration__entry">
            <input
              type="radio"
              name="allowance__form__duration__entry__input"
              className="allowance__form__duration__entry__input"
              onChange={() => {
                setStakingOption(2);
              }}
            />
            <div className="allowance__form__duration__entry__content">
              90 Days
            </div>
          </div>
        </div>
        <div className="allowance__form__buttons">
          <button
            className="allowance__form__buttons__secondary"
            disabled={disable}
            onClick={onStake}
          >
            Stake
          </button>
        </div>
      </div>
    </>
  );
}
