export default function AboutSection({ logoBig }) {
  return (
    <section id="about__section" className="about__section">
      <img src={logoBig} alt="logoBig" className="about__section__left__img" />
      <div className="about__section__right">
        <div className="about__section__right__heading">
          What is Bullstarter?
        </div>
        <div className="about__section__right__info">
          Bullstarter is a decentralised fundraising platform supercharging the
          DeFi, NFT, Metaverse, and Gaming ecosystems by selecting top-tier
          projects using a stringent and unique project selection process.
        </div>
      </div>
    </section>
  );
}
