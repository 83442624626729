import { Link } from "react-router-dom";

export function NavLink({ to, children, isPending }) {
  return (
    <Link to={to} className="header__nav__link">
      {children}
      {isPending ? (
        <div className="header__nav__link__hover">Coming Soon</div>
      ) : null}
    </Link>
  );
}
