import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import Stake from "./screens/Stake";
import IDO from "./screens/IDO";
import Sales from "./screens/Sales";
import Vote from "./screens/Vote";
import { ethers } from "ethers";
import "./App.scss";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [isOn, setIsOn] = useState("home");

  const [data, setdata] = useState({
    address: "",
    Balance: null,
  });

  // Button handler button for handling a
  // request event for metamask
  const btnhandler = () => {
    // Asking if metamask is already present or not
    if (window.ethereum) {
      // res[0] for fetching a first wallet
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res) => accountChangeHandler(res[0]));
    } else {
      alert("install metamask extension!!");
    }
  };

  // getbalance function for getting a balance in
  // a right format with help of ethers
  const getbalance = (address) => {
    // Requesting balance method
    window.ethereum
      .request({
        method: "eth_getBalance",
        params: [address, "latest"],
      })
      .then((balance) => {
        // Setting balance
        setdata({
          Balance: ethers.utils.formatEther(balance),
        });
      });
  };

  // Function for getting handling all events
  const accountChangeHandler = (account) => {
    // Setting an address data
    setdata({
      address: account,
    });

    // Setting a balance
    getbalance(account);
  };

  return (
    <>
      <Header setWalletAddress={btnhandler} data={data} isOn={isOn} />
      <div className="App">
        <Routes>
          <Route
            path="/"
            element={
              <Home setWalletAddress={setWalletAddress} setIsOn={setIsOn} />
            }
          />
          <Route
            path="/ido"
            element={
              <IDO setWalletAddress={setWalletAddress} setIsOn={setIsOn} />
            }
          />
          <Route
            path="/sales"
            element={<Sales walletAddress={walletAddress} setIsOn={setIsOn} />}
          />
          <Route
            path="/vote"
            element={<Vote walletAddress={walletAddress} setIsOn={setIsOn} />}
          />
          <Route
            path="/stake"
            element={<Stake walletAddress={walletAddress} setIsOn={setIsOn} />}
          />
        </Routes>
      </div>
      <Footer />
    </>
  );
}

export default App;

// Author : hammadhabib64@gmail.com | mehfoozijaz786@gmail.com
