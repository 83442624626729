import { Twitter, Globe, Send } from "react-feather";
import { Link } from "react-router-dom";
import logoDark from "../assets/logoDark.webp";

export default function Footer() {
  return (
    <section id="footer" className="footer__conatiner">
      <div className="footer__conatiner__content">
        <div className="footer__overlay__content__col">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                top: 0,
                behavior: "smooth",
              });
            }}
            className="footer__overlay__content__col__img__wrapper"
          >
            <img
              src={logoDark}
              alt="logoDark"
              style={{ width: 350 }}
              className="footer__overlay__content__col__img"
            />
          </Link>
          <div className="footer__overlay__content__col__text">
            Where the Bull Run Begins
          </div>
          <a
            href="mailto:afsar@bullstarter.xyz"
            className="footer__overlay__content__col__text"
            style={{ marginTop: 20 }}
          >
            afsar@bullstarter.xyz
          </a>
        </div>

        <div className="footer__overlay__content__col">
          <a
            href="https://twitter.com/bullstarter_xyz"
            className="footer__overlay__content__col__entry"
          >
            <Twitter size={20} color="currentColor" />
          </a>
          <a
            href=" https://t.me/bullstarter_xyz"
            className="footer__overlay__content__col__entry"
          >
            <Send size={20} color="currentColor" />
          </a>
          <a
            href="https://bullstarter.medium.com/"
            className="footer__overlay__content__col__entry"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              fill="white"
            >
              <path d="M28 28v456h456V28H28zm378.83 108.04l-24.46 23.45a7.162 7.162 0 00-2.72 6.86v172.28c-.44 2.61.61 5.26 2.72 6.86l23.88 23.45v5.15H286.13v-5.15l24.74-24.02c2.43-2.43 2.43-3.15 2.43-6.86V198.81l-68.79 174.71h-9.3l-80.09-174.71v117.1c-.67 4.92.97 9.88 4.43 13.44l32.18 39.03v5.15h-91.24v-5.15l32.18-39.03c3.44-3.57 4.98-8.56 4.15-13.44V180.5c.38-3.76-1.05-7.48-3.86-10.01l-28.6-34.46v-5.15h88.81l68.65 150.55 60.35-150.55h84.66v5.16z" />
            </svg>
          </a>
          <a
            href="https://docs.bullstarter.xyz"
            className="footer__overlay__content__col__entry"
          >
            <Globe size={20} color="currentColor" />
          </a>
        </div>
      </div>
    </section>
  );
}
