import { useState } from "react";
import { getValue, getValueOfDate } from "../utils/getValue";

export default function TableRow({ data, lock, index, unStake }) {
  const [disable, setDisable] = useState(false);

  let claim_allowed_time =
    getValueOfDate(data.claim_allowed_time._hex) -
    (Date.now() / 1000).toFixed();
  if (claim_allowed_time < 0) {
    claim_allowed_time = 0;
  }
  let days = 0;
  let hours = 0;
  let minutes = 0;
  let seconds = 0;
  if (claim_allowed_time >= 3600 * 24) {
    days = Math.floor(claim_allowed_time / (3600 * 24));
  } else if (claim_allowed_time >= 3600) {
    hours = Math.floor(claim_allowed_time / 3600);
  } else if (claim_allowed_time >= 60) {
    minutes = Math.floor(claim_allowed_time / 60);
  } else {
    seconds = Math.floor(claim_allowed_time);
  }

  function getMonthFunc(value) {
    return new Date(getValueOfDate(value) * 1000).getMonth() + 1;
  }
  console.log(new Date(getValueOfDate(data.stake_time._hex) * 1000).getMonth());
  return (
    <div className="stake__table__content__row">
      <div className="stake__table__content__row__entry">
        {new Date(getValueOfDate(data.stake_time._hex) * 1000).getDate() +
          "-" +
          getMonthFunc(data.stake_time._hex) +
          "-" +
          new Date(getValueOfDate(data.stake_time._hex) * 1000).getFullYear()}
      </div>
      <div className="stake__table__content__row__entry">
        {getValue(data.amount)}
      </div>
      <div className="stake__table__content__row__entry">
        {days != 0
          ? days + " Days"
          : hours != 0
          ? hours + " Hours"
          : minutes != 0
          ? minutes + " Minutes"
          : seconds + " Seconds"}
      </div>
      <div className="stake__table__content__row__entry">
        {getValueOfDate(data.reward_amount._hex)}
      </div>
      <div className="stake__table__content__row__entry">{data.apr}</div>
      <div className="stake__table__content__row__entry">
        <button
          className="stake__table__content__row__entry__button stake__table__content__row__entry__button__primary"
          disabled={disable ? true : lock && claim_allowed_time != 0}
          onClick={() => {
            unStake(index);
          }}
        >
          {lock === false && claim_allowed_time != 0 ? "Unstake" : "Claim"}
        </button>
      </div>
    </div>
  );
}
