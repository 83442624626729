export default function IDO() {
  return (
    <>
      <div className="home__section" style={{ marginBottom: 50, height: 500 }}>
        <div
          className="home__section__entry"
          style={{
            fontSize: 30,
            fontWeight: "bold",
            color: "white",
          }}
        >
          Applications are not open yet
        </div>
      </div>
    </>
  );
}
