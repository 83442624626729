import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";
import Lottie from "react-lottie";
import logoBig from "../assets/logoBig.webp";
import roadMapPic from "../assets/roadMapPic.webp";

import lunastarer from "../assets/lunastarter.webp"
import AboutSection from "../components/AboutSection";
import PartnerSection from "../components/PartnerSection";
import TeamSection from "../components/TeamSection";
import RoadMapSection from "../components/RoadMapSection";
import * as bull from "../assets/bull.json";

export default function Home({ setIsOn }) {
  const navigate = useNavigate();
  const [slidesPerView, setSlidesPerView] = useState(6);
  const [teamsPerView, setTeamsPerView] = useState(4);
  SwiperCore.use([Autoplay]);
  useEffect(() => {
    setIsOn("home");
    if (window.innerWidth <= 400) {
      setSlidesPerView(1);
      setTeamsPerView(1);
    } else if (window.innerWidth <= 550) {
      setSlidesPerView(2);
      setTeamsPerView(2);
    } else if (window.innerWidth <= 900) {
      setSlidesPerView(3);
      setTeamsPerView(3);
    } else if (window.innerWidth <= 1360) {
      setSlidesPerView(4);
      setTeamsPerView(4);
    } else if (window.innerWidth <= 1440) {
      setSlidesPerView(5);
      setTeamsPerView(4);
    } else {
      setSlidesPerView(6);
      setTeamsPerView(4);
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 400) {
        setSlidesPerView(1);
        setTeamsPerView(1);
      } else if (window.innerWidth <= 550) {
        setSlidesPerView(2);
        setTeamsPerView(1);
      } else if (window.innerWidth <= 900) {
        setSlidesPerView(3);
        setTeamsPerView(2);
      } else if (window.innerWidth <= 1360) {
        setSlidesPerView(4);
        setTeamsPerView(4);
      } else if (window.innerWidth <= 1440) {
        setSlidesPerView(5);
        setTeamsPerView(4);
      } else {
        setSlidesPerView(6);
        setTeamsPerView(4);
      }
    });
  }, [window.innerWidth]);

  const defaultOptions = {
    loop: false,
    autoplay: true,
    animationData: bull,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <div className="home__section" style={{ marginBottom: "2em" }}>
        <Lottie
          options={defaultOptions}
          style={{
            paddingBottom: "2em",
            width: "100%",
            maxWidth: 500,
            height: 300,
          }}
        />
        <div className="home__section__heading">
          Where the <span>Bull</span> Run Begins
        </div>
        <div className="home__section__buttons">
          <button
            className="home__section__buttons__btn home__section__buttons__btn__primary"
            onClick={() => {
              window.location.href = "https://whitelist.bullstarter.xyz";
            }}
          >
            Get Whitelisted
          </button>
          <a
            href="https://t.me/BullStarter_xyz"
            target="_blank"
            className="home__section__buttons__btn home__section__buttons__btn__secondary"
          >
            Join Community
          </a>
        </div>
        <div
          style={{
            color: "white",
            fontSize: 25,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            marginTop: "2em",
          }}
        >
          Previously
          <p></p>
          <img
            src={lunastarer}
            alt="lunastarter"
            style={{ height: 50, marginTop: 0, marginBottom: -50 }}
          />
        </div>
      </div>
      {/* <FeatureSection /> */}
      <AboutSection logoBig={logoBig} />
      <RoadMapSection roadMapPic={roadMapPic} />
    </>
  );
}
