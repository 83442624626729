import { useEffect, useState } from "react";
import { ethers } from "ethers";
import local from "../local.json";
import { getValueOfDate } from "../utils/getValue";

export default function Sales() {
  const [bought, setBought] = useState(0);
  const [remaining, setRemaining] = useState(0);
  const [limit, setLimit] = useState(0);
  const [limitUsed, setLimitUsed] = useState(0);
  const [totalRemainingAmount, setTotalRemainingAmount] = useState(0);
  const [totalSoldOutAmount, setTotalSoldOutAmount] = useState(0);
  const [totalRemaining, setTotalRemaining] = useState(0);
  const [totalSoldOut, setTotalSoldOut] = useState(0);
  const [saleAmount, setSaleAmount] = useState("");
  const [disable, setDisable] = useState(false);
  const [weiRatio, setWeiRatio] = useState(false);
  const [coinRatio, setCoinRatio] = useState(false);

  const provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  function getDataFromUser(tb1, cr, wr) {
    // console.log(tb1 * (cr / wr));
    // console.log("tb1", tb1);
    // console.log("wr", wr);
    // console.log("cr", cr);
    return tb1 * (cr / wr);
  }

  async function onBuyBST() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();

    const usdcContract = new ethers.Contract(
      local.sales.address,
      local.sales.abi,
      signer
    );

    const tx = await usdcContract.buy({
      value: ethers.utils.parseEther("0.000000000000000010"),
      gasPrice: 20e9,
    });
    console.log(`Transaction hash: ${tx.hash}`);

    const receipt = await tx.wait();
    console.log(`Transaction confirmed in block ${receipt.blockNumber}`);
    console.log(`Gas used: ${receipt.gasUsed.toString()}`);
    OnSalesQuery();
  }

  async function OnSalesQuery() {
    await provider.send("eth_requestAccounts", []);
    const signer = provider.getSigner();
    let userAddress = await signer.getAddress();

    const usdcContract = new ethers.Contract(
      local.sales.address,
      local.sales.abi,
      signer
    );

    let query = await usdcContract.userData(userAddress);
    console.log(query);

    setLimit(getValueOfDate(query.state.limit._hex));
    setLimitUsed(getValueOfDate(query.bought._hex));
    setTotalSoldOutAmount(
      (getValueOfDate(query.state.total_sold._hex) /
        getValueOfDate(query.state.total_sale._hex)) *
        100
    );
    setTotalRemainingAmount(
      (getValueOfDate(query.state.remaining_amount._hex) /
        getValueOfDate(query.state.total_sale._hex)) *
        100
    );
    setTotalSoldOut(getValueOfDate(query.state.total_sold._hex));
    setTotalRemaining(getValueOfDate(query.state.remaining_amount._hex));
    setBought(
      (getValueOfDate(query.bought._hex) /
        getValueOfDate(query.state.limit._hex)) *
        100
    );
    setRemaining(
      ((getValueOfDate(query.state.limit._hex) -
        getValueOfDate(query.bought._hex)) /
        getValueOfDate(query.state.limit._hex)) *
        100
    );
    setWeiRatio(getValueOfDate(query.state.wei_ratio._hex));
    setCoinRatio(getValueOfDate(query.state.sale_coin_ratio._hex));
  }
  useEffect(() => {
    OnSalesQuery();
  }, []);

  return (
    <>
      <div className="sales__section">
        <div className="sales__section__stats">
          <div className="sales__section__stats__row">
            <div className="sales__section__stats__row__entry">
              {parseInt(totalSoldOutAmount)}%
            </div>
            <div className="sales__section__stats__row__entry">
              {parseInt(totalRemainingAmount)}%
            </div>
          </div>
          <div className="sales__section__stats__bar">
            <div
              className="sales__section__stats__bar__complete"
              style={{ width: totalSoldOutAmount + "%" }}
            ></div>
            <div
              className="sales__section__stats__bar__un__complete"
              style={{
                width: totalRemainingAmount + "%",
              }}
            ></div>
          </div>
          <div className="sales__section__stats__row">
            <div className="sales__section__stats__row__entry">
              BST Sold Out ({totalSoldOut})
            </div>
            <div className="sales__section__stats__row__entry">
              BST Remaining ({totalRemaining})
            </div>
          </div>
        </div>
        <div className="sales__section__content">
          <div className="sales__section__content__left">
            <div className="sales__section__content__left__heading">Sales</div>
            <div className="sales__section__content__left__sub__heading">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen
            </div>
            <input
              type="number"
              className="allowance__form__input"
              disabled={true}
              defaultValue={0}
              value={getDataFromUser(saleAmount, coinRatio, weiRatio)}
            />
            <input
              type="number"
              placeholder="Enter Wei Value"
              className="allowance__form__input"
              value={saleAmount}
              onChange={(e) => {
                setSaleAmount(e.target.value);
              }}
            />
            <button
              className="home__section__buttons__btn home__section__buttons__btn__secondary"
              style={{
                padding: ".7em 2em",
                marginLeft: "0em",
                fontSize: 15,
              }}
              disabled={disable}
              onClick={onBuyBST}
            >
              Buy BST
            </button>
          </div>
          <div className="sales__section__content__right">
            <div className="sales__section__content__right__sale__on__lst">
              <div className="sales__section__content__right__sale__on__lst__entry">
                BST limit
              </div>
              <div className="sales__section__content__right__sale__on__lst__entry">
                {limit}
              </div>
            </div>
            <div className="sales__section__content__right__swap__rate">
              <div className="sales__section__content__right__swap__rate__entry">
                BST bought by me
              </div>
              <div className="sales__section__content__right__swap__rate__entry">
                {limitUsed}
              </div>
            </div>
            <div className="sales__section__content__right__stats">
              <div className="sales__section__content__right__stats__row">
                <div className="sales__section__content__right__stats__row__entry">
                  {bought} %
                </div>
                <div className="sales__section__content__right__stats__row__entry">
                  {remaining} %
                </div>
              </div>
              <div className="sales__section__content__right__stats__bar">
                <div
                  className="sales__section__content__right__stats__bar__complete"
                  style={{
                    width: bought + "%",
                  }}
                />
                <div
                  className="sales__section__content__right__stats__bar__un__complete"
                  style={{
                    width: remaining + "%",
                  }}
                />
              </div>
              <div className="sales__section__content__right__stats__row">
                <div className="sales__section__content__right__stats__row__entry">
                  Bought
                </div>
                <div className="sales__section__content__right__stats__row__entry">
                  Remaining
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
